<template>
  <div>
    <b-card>
      <b-row
        v-if="loading"
        align-h="center"
      >
        <div
          class="loader"
        />
      </b-row>
      <b-row v-else>
        <b-col>
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form
              v-if="liveData"
              ref="myForm"
              class="p-2"
              @submit.prevent
            >
              <fieldset :disabled="$attrs.disabled">
                <b-row>
                  <b-col md="6">
                    <inpo-select
                      :inpo-id.sync="entityId"
                      :is-disabled="$attrs.disabled"
                    />
                  </b-col>

                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Speaker"
                    >
                      <b-form-group
                        label="Speaker"
                        label-for="speaker"
                      >
                        <b-form-input
                          id="speaker"
                          v-model="liveData.speaker"
                          placeholder="Speaker"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="6">

                    <text-input
                      id="live-topic"
                      name="Topic"
                      rules="required"
                      :text.sync="liveData.activity_name"
                    />

                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="Live Description"
                    >
                      <b-form-group
                        label="Live Description"
                        label-for="live_des"
                      >
                        <b-form-input
                          id="live_des"
                          v-model="liveData.activity_description"
                          placeholder="Live Description"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <text-input
                      id="live-link"
                      name="Link"
                      rules="required"
                      :disabled="$moment().isAfter(liveData.session_start)"
                      :text.sync="liveData.session_link"
                    />

                  </b-col>

                  <b-col md="6">
                    <language-select
                      :languages.sync="liveData.languages"
                    />
                  </b-col>

                  <b-col md="6">
                    <date-time
                      id="live-start-date-time"
                      :datetime.sync="liveData.session_start"
                      name="Start Date Time"
                      rules="required"
                      :disabled="$moment().isAfter(liveData.session_start)"
                      :is-edit-form-prop="true"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                    />
                  </b-col>

                  <b-col md="6">
                    <date-time
                      id="live-end-date-time"
                      :datetime.sync="liveData.session_end"
                      name="End Date Time"
                      rules="required"
                      :is-edit-form-prop="true"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        minDate:$moment(liveData.session_start).add({minutes:1}).toDate() }"
                    />
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      name="To Be Raised"
                      rules="numeric"
                    >
                      <b-form-group
                        label="To Be Raised"
                        label-for="to_be_raised"
                      >
                        <b-form-input
                          id="to_be_raised"
                          v-model="liveData.session_to_be_raised"
                          type="number"
                          placeholder="To Be Raised"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <text-input
                      id="order"
                      name="Order"
                      type="number"
                      :text.sync="liveData.activityOrder"
                    />
                  </b-col>

                  <b-col
                    md="6"
                    class="my-2"
                  >
                    <b-checkbox v-model="liveData.hide_poll_winner">
                      Hide poll winner
                    </b-checkbox>
                  </b-col>
                  <b-col
                    cols="12"
                    class="border mb-2 py-2"
                  >
                    <h2>
                      Custom Section
                    </h2>
                    <b-row>
                      <b-col cols="12">
                        <rich-text-editor
                          name="Description"
                          rules="max:2048"
                          :description.sync="liveData.custom_section"
                        />
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col>
                            <date-time
                              id="custom-section-start-time"
                              :datetime.sync="liveData.custom_section_starts_at"
                              name="Custom Section Start Time"
                              :rules="`${liveData.custom_section ? 'required':''}`"
                              :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: $moment(liveData.session_start).add({minutes:1}).toDate(),maxDate:liveData,session_end}"
                            />
                          </b-col>
                          <b-col>
                            <date-time
                              id="custom-section-end-time"
                              :datetime.sync="liveData.custom_section_ends_at"
                              name="Custom Section End Time"
                              :rules="`${liveData.custom_section ? 'required':''}`"
                              :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: liveData.custom_section_starts_at,maxDate:liveData.session_end}"
                            />
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12">
                    <div>
                      <upload-files
                        v-if="!$attrs.disabled"
                        id="live-image"
                        label="Live Cover"
                        accept="image/png, image/jpeg"
                        :files.sync="liveData.cover_image"
                        rules="ext:jpg,jpeg,png|min-dimension:300,200|image-aspect-ratio:16,9"
                      >
                        <template #preview>
                          <div>
                            <images-preview
                              :images.sync="liveData.cover_image"
                            />
                          </div>
                        </template>
                      </upload-files>
                    </div>
                  </b-col>

                </b-row>
              </fieldset>
              <div class="text-right">
                <back class="mr-1" />
                <submit-button
                  v-if="!$attrs.disabled"
                  :handle-submit="handleSubmit"
                  :submit="editLive"
                />
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import InpoSelect from '@/common/components/LiveManagement/InpoLinkedWithStripeSelect.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import Back from '@/common/components/common/FormInputs/Back.vue'
import LanguageSelect from '@/common/components/common/FormInputs/LanguageSelect.vue'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import UploadFiles from '@/common/components/common/FormInputs/UploadFiles.vue'
import ImagesPreview from '@/common/components/common/FormInputs/ImagesPreview.vue'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import RichTextEditor from '@/common/components/common/FormInputs/RichTextEditor.vue'

export default {
  name: 'EditLive',
  components: {
    InpoSelect,
    Back,
    LanguageSelect,
    SubmitButton,
    UploadFiles,
    ImagesPreview,
    TextInput,
    DateTime,
    RichTextEditor,
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert } = handleAlerts()
    const { setFormData, formData } = handleFormData()

    return {
      getValidationState,
      successfulOperationAlert,

      setFormData,
      formData,
    }
  },
  data() {
    return {
      liveData: null,
      loading: true,
    }
  },
  computed: {
    entityId() {
      return this.liveData.entity_id?.id
    },
  },
  mounted() {
    this.getLive()
  },
  methods: {
    getLive() {
      this.$activities.get(`internalops/live/${this.$router.currentRoute.params.id}?with-reactions=false`).then(res => {
        this.liveData = { ...res.data.data, cover_image: [res.data.data.cover_image] }
        this.parseLanguages()
        this.loading = false
      })
    },
    parseLanguages() {
      this.liveData.languages = this.liveData.languages?.split(',').map(id => parseInt(id, 10))
    },
    editLive() {
      this.setEntityID()
      const { cover_image, ...liveData } = this.liveData
      this.setFormData(liveData)
      this.appendImage()
      return this.$activities.post(`internalops/live/${this.$router.currentRoute.params.id}?_method=PUT`, this.formData).then(() => {
        this.successfulOperationAlert('Live is Updated successfully')
        this.$router.push({ name: 'live-management-list' })
      })
    },
    appendImage() {
      if (this.liveData.cover_image[0] instanceof File) {
        this.formData.append('cover_image', this.liveData.cover_image[0])
      }
    },
    convertLanguagesArrayToString() {
      this.liveData.languages = this.liveData.languages.toString()
    },
    setEntityID() { // !! should be removed when api fix this point
      if (typeof this.liveData.entity_id === 'object') this.liveData.entity_id = this.liveData.entity_id?.id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
