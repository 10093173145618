<template>
  <div>
    <edit-live :disabled="true" />
  </div>
</template>
<script>
import editLive from './editLive.vue'

export default {
  name: 'LiveView',
  components: { editLive },
}
</script>
<style lang="">

</style>
